<template>
  <v-dialog
    v-model="show"
    hide-overlay
    transition="dialog-bottom-transition"
    width="400"
  >
    <v-card :color="dark" class="" @drop.prevent="drop">
        <v-card-title class="text-center">
          <v-spacer></v-spacer>
          <span> Rate writer - {{ orderDetail.writer.nickname ? orderDetail.writer.nickname : orderDetail.writer.id }}</span>
          <v-spacer></v-spacer>
          <v-btn
          icon
          tile
          :color="modalColor"
          class=""
          @click="$emit('close', false)"
          >
            <v-icon >
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert
            :type="actionClass"
            class="mr-15"
            v-if="message"
            dismissible
          >
            {{ message }}
          </v-alert>
          <div class="text-center">
            <span >{{ orderDetail.writer.nickname ? orderDetail.writer.nickname : orderDetail.writer.id }} will get your feedback, alongside your name and order associated with this rating.  </span>
          </div>

          <div class="d-flex">
            <v-rating
              v-model="rating"
              half-increments
              background-color="orange lighten-3"
              color="orange"
              class="ml-0"
              size="50"
            ></v-rating>
           <span class="text-primary mt-6"> {{ rating }}/5 </span>
          </div>
          <div class="text-center">
            <span >Tap a star to rate </span>
          </div>
        </v-card-text>
        <v-card-text v-if="rating > 0">
          <v-textarea
          v-model="review"
          outlined
          placeholder="Leave your review (Optional)"
          background-color="transparent"
          >
          </v-textarea>
        </v-card-text>

        <v-card-actions class="py-5 mt-n7" v-if="rating > 0">
          <v-spacer></v-spacer>
          <v-btn :color="primaryBlue" @click="submitRating" :loading="loading"> Submit </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin"

export default {
  name: "Rating",
  props: ["show", 'orderDetail'],
  mixins: [colorMxn, validationMxn],
  data() {
    return {
      rating: 0,
      review: '',
      message: '',
      actionClass: 'success',
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['getSession', 'getReload']),
  },
  methods: {
    ...mapMutations(['setReload']),
    ...mapActions(['performPostActions']),
    async submitRating() {
      
      if (this.rating === 0 ) {
        this.message = 'Kinldy tap star to rate a writer';
        this.actionClass = 'error';
        return;
      }
      const payload = {
        order_id: this.$route.params.id,
        writer_id: this.orderDetail.writer.id,
        customer_id: this.getSession.customer.id,
        rating: this.rating,
        review: this.review,
      };
      this.loading = true;
      const fullPayload = {
        params: payload,
        endpoint: '/rating/store',
      };
      const response = await this.performPostActions(fullPayload);
      if (response.data.status) {
        this.setReload(true);
      }
      this.message = response.data.message
      this.actionClass = response.data.status ? 'success' : 'error';
      this.loading = false;
      setTimeout(() => {
        this.$emit('close');
      }, 3000);
    }
  }
  
}
</script>